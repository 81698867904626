import { useAdminApi } from '@hooks/useAdminApi';
import { Box, Link, List, ListItem, styled, Switch, Typography } from '@mui/material';
import { Task } from '@protos/tasks';
import theme from '@shared/themes/darkTheme';
import { formatDatetime } from '@utils/date';
import { useCallback, useEffect, useState } from 'react';
import { TaskPiority } from './TaskPiority';
import { TaskState } from './TaskState';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.darker,
  justifyContent: 'space-between',
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.lighter,
  },
  alignSelf: 'center',
  '&:hover': {
    backgroundColor: theme.palette.background.lightHover,
  },
}));

const generateComma = (showComma: boolean) => <span>{showComma ? ',' : ''}</span>;

export const TaskList: React.FC<{}> = () => {
  const { apiClient } = useAdminApi();
  const [tasks, setTasks] = useState<Task[]>([]);

  const fetchTasks = useCallback(async () => {
    if (!apiClient) return;
    try {
      const data = await apiClient.getTasks();
      setTasks(data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setTasks([]);
    }
  }, [apiClient]);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  const updateTask = useCallback(
    async (task: Task) => {
      if (!apiClient) return;
      try {
        await apiClient.updateTask(task.name, { enabled: !task.enabled });
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    },
    [apiClient]
  );

  const updateTasksList = useCallback(
    (task: Task) => {
      updateTask(task);
      const newTasks = [...tasks];
      const taskIndexToModify = newTasks.findIndex(elem => elem.name === task.name);
      newTasks[taskIndexToModify].enabled = !task.enabled;
      setTasks(newTasks);
    },
    [updateTask, tasks]
  );

  return (
    <List style={{ padding: 0 }}>
      {tasks.map(task => (
        <StyledListItem key={`task-${task.name}`} data-testid={`task-item-${task.name}`}>
          <Box display="flex" flexDirection="column" alignItems="start">
            <Box display="flex" flexDirection="row" alignItems="start" gap={0.5}>
              <TaskPiority piority={task.priority} />
              <Link
                variant="h6"
                style={{ fontSize: 12, cursor: 'pointer', color: theme.palette.text.primary }}
                onClick={() =>
                  window.open(`${process.env.REACT_APP_GRAFANA_URL}a1209cb7-6252-4fc5-8b4f-d3e1ab1da4c8/tasks?var-task=${task.name}`, '_blank')
                }
              >
                {task.name}
              </Link>
            </Box>
            <Typography
              variant="caption"
              style={{
                fontSize: 11,
                paddingRight: '10px',
              }}
            >
              {task.description}
            </Typography>
            <Box display="flex" gap={1} alignItems="center" justifyContent={'center'}>
              {task.last_run_state && (
                <>
                  <Typography variant="caption" style={{ fontSize: 11 }}>
                    Last run:
                  </Typography>
                  <TaskState state={task.last_run_state} />
                </>
              )}
              {task.last_run_end && (
                <>
                  <Typography variant="caption" style={{ fontSize: 11 }}>
                    {formatDatetime(task.last_run_end)}
                    {generateComma(!!task.last_run_duration)}
                  </Typography>
                </>
              )}
              {task.last_run_duration && (
                <>
                  <Typography variant="caption" style={{ fontSize: 11 }}>
                    duration: {parseInt(task.last_run_duration) / 1000} sec
                    {generateComma(!!task.schedule)}
                  </Typography>
                </>
              )}
              {task.schedule && (
                <Typography variant="caption" style={{ fontSize: 11 }}>
                  schedule: {task.schedule}
                </Typography>
              )}
            </Box>
          </Box>
          <Switch
            key={`switch-${task.name}`}
            data-testid={`switch-${task.name}`}
            name={task.name}
            size="small"
            checked={task.enabled}
            onChange={event => {
              event?.stopPropagation();
              updateTasksList(task);
            }}
          />
        </StyledListItem>
      ))}
    </List>
  );
};
