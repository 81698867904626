import { Typography } from '@mui/material';
import { TaskLastRunState } from '@protos/tasks';
import { getStateColor } from './taskUtils';

export const TaskState: React.FC<{ state: TaskLastRunState }> = ({ state }) => {
  return (
    <Typography variant="caption" style={{ fontSize: 11 }} color={getStateColor(state)}>
      {state}
    </Typography>
  );
};
