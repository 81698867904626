import { Box, Divider, Stack, Switch, Typography, useMediaQuery } from '@mui/material';
import { Snapshot } from '@protos/snapshots';
import { ContractRisk } from '@protos/trading';
import theme from '@shared/themes/darkTheme';
import { useState } from 'react';
import { useTradingKillSwitch } from '../../hooks/useTradingKillSwitch';
import { useTradingPeriodicPnl } from '../../hooks/useTradingPeriodicPnl';
import { TradingRiskGraph } from './TradingRiskGraph';
import { TradingRiskGrid } from './TradingRiskGrid';
import { TradingRiskKillSwitch } from './TradingRiskKillSwitch';

interface TradingRiskChartAndMetaProps {
  data: ContractRisk[];
  metaData?: { version: number; timestamp: string };
  snapshot?: Snapshot;
  isMobileLandscape: boolean;
}

export const TradingRiskChartAndMeta: React.FC<TradingRiskChartAndMetaProps> = ({ data, metaData, snapshot, isMobileLandscape }) => {
  const { killSwitch, onToggleKillSwitch } = useTradingKillSwitch();
  const { pnlData } = useTradingPeriodicPnl();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [showGraph, setShowGraph] = useState(true);

  return (
    <Box display="flex" flex={1} width="100%" minHeight={isMdScreen ? 'none' : '70%'}>
      {!isMdScreen && (
        <>
          <TradingRiskGraph isMobileLandscape={isMobileLandscape} metaData={metaData} data={data} />
          <Divider orientation="vertical" />
          <Stack width={!isMobileLandscape ? '30%' : '100%'} height="100%">
            <TradingRiskKillSwitch killSwitch={killSwitch} onToggleKillSwitch={onToggleKillSwitch} snapshot={snapshot} />
            <TradingRiskGrid data={data} pnlData={pnlData} />
          </Stack>
        </>
      )}
      {isMdScreen && (
        <Stack display="flex" flexDirection="column" flex={1} gap={1} padding={2}>
          <Stack display="flex" flexDirection="row" gap={1}>
            <Typography variant="h7">Grid</Typography>
            <Switch
              key={`mobile-risk-switch`}
              id="risk"
              name="enabled"
              size="small"
              defaultChecked={showGraph}
              checked={showGraph}
              onChange={(_, val) => setShowGraph(val)}
            />
            <Typography variant="h7">Graph</Typography>
          </Stack>
          <TradingRiskKillSwitch killSwitch={killSwitch} onToggleKillSwitch={onToggleKillSwitch} snapshot={snapshot} />
          {showGraph && (
            <Stack width="100%" flex={1} minHeight={'300px'}>
              <TradingRiskGraph isMobileLandscape={isMobileLandscape} metaData={metaData} data={data} />
            </Stack>
          )}
          {!showGraph && (
            <Stack width="100%" flex={1} minHeight={'500px'}>
              <TradingRiskGrid data={data} pnlData={pnlData} />
            </Stack>
          )}
        </Stack>
      )}
    </Box>
  );
};
