import { alpha, createTheme } from '@mui/material';
import { themeFontSizes } from './fontSizes';

declare module '@mui/material/styles' {
  interface TypeBackground {
    lighter: string;
    darker: string;
    popover: string;
    main: string;
    infoRow: string;
    buy: string;
    sell: string;
    lightHover?: string;
  }

  interface TypographyVariants {
    fontWeightExtraBold: number;
    input: {
      fontSize: number;
    };
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    fontWeightExtraBold?: number;
    input: {
      fontSize: number;
    };
  }

  interface TypeText {
    input?: string;
    header?: string;
  }

  interface PaletteOptions {
    border?: string;
  }

  interface TypeAction {
    inputHover?: string;
  }

  interface Palette {
    border: string;
  }
}

let theme = createTheme({
  typography: {
    fontFamily: ['Noto Sans'].join(','),
    fontWeightExtraBold: 900,
    input: {
      fontSize: 11,
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#4189E8',
    },
    secondary: {
      main: '#282B31',
    },
    background: {
      default: '#1D1F28',
      lighter: '#282A33',
      darker: '#12141A',
      popover: '#292B33',
      main: '#010101',
      infoRow: '#9ba0a3',
      buy: '#4189E8',
      sell: '#CD4B4B',
      lightHover: '#65686e',
    },
    success: {
      main: '#09FB9D',
    },
    warning: {
      main: '#FF9900',
      light: '#ffbb00',
    },
    error: {
      main: '#FF6D46',
    },
    text: {
      primary: '#FFFFF2',
      disabled: '#999999',
      secondary: '#999999',
      input: '#FFFFF2',
    },
    border: '#F3FFFF',
    action: {
      hover: '#7ba8e3',
      inputHover: '#232933',
    },
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          transition: 'none',
          '&:hover': {
            color: theme.palette.text.primary,
            backgroundColor: `${theme.palette.action.hover} !important`,
          },
          '&:focus': {
            color: theme.palette.text.primary,
            backgroundColor: `${theme.palette.action.hover} !important`,
          },
          '&.Mui-selected:hover': {
            backgroundColor: `${theme.palette.action.hover} !important`,
          },
          '&:focus-visible': {
            borderColor: `${theme.palette.primary.main} !important`,
          },
          '&.Mui-focusVisible': {
            borderColor: `${theme.palette.primary.main} !important`,
          },
          '&.Mui-disabled': {
            color: `${alpha(theme.palette.text.primary, 0.3)} !important`,
          },
        }),
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          transition: 'none',
          '&:hover': {
            color: theme.palette.text.primary,
            backgroundColor: `${theme.palette.action.hover} !important`,
          },
          '&.Mui-selected:hover': {
            backgroundColor: `${theme.palette.action.hover} !important`,
          },
          '&:focus-visible': {
            borderColor: `${theme.palette.primary.main} !important`,
          },
          '&.Mui-focusVisible': {
            borderColor: `${theme.palette.primary.main} !important`,
          },
          '&.Mui-disabled': {
            color: `${alpha(theme.palette.text.primary, 0.3)} !important`,
          },
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.secondary,
          transition: 'none',
          '&:hover': {
            color: theme.palette.text.primary,
            backgroundColor: 'unset',
          },
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          boxShadow: `0 2px 10px 0 ${alpha(theme.palette.common.black, 0.25)}`,
          transition: 'none !important',
        }),
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: 'fit-content !important',
          maxHeight: '350px !important',
          //add easy ease transition to colapse
          transition: 'ease-in-out 0.3s',
          overflowY: 'auto',
        }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({ theme }) => ({
          height: 40,
          boxSizing: 'border-box',
          '&:nth-of-type(even)': {
            '&:hover': {
              backgroundColor: '#1C1E25',
            },
          },
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.lighter,
            '&:hover': {
              backgroundColor: '#32343F',
            },
          },
          '&:first-of-type > td:first-of-type': {
            borderTopLeftRadius: theme.shape.borderRadius,
          },
          '&:first-of-type > td:last-of-type': {
            borderTopRightRadius: theme.shape.borderRadius,
          },
        }),
      },
    },
  },
});

theme = themeFontSizes(theme);

export default theme;
