import ApiClient from '@services/ApiClient';
import { Dashboard } from '@shared/protos/dashboard';
import { v4 as uuidv4 } from 'uuid';
import { AppLoggerService } from './AppLoggerService';
import AppConfig from './Config';
import { Stream, StreamEvent, Subscription } from './Stream';
import { toastifyService } from './ToastifyService';

export const dashboardJson = (dashboard: Dashboard) => {
  const json = dashboard.json || {};
  dashboard.json = json;
  return json;
};

//Sorts dashboards alphabettically
const getSortedDashboards = (dashboards: Dashboard[]) => dashboards.sort((dashboard1, dashboard2) => dashboard1.name.localeCompare(dashboard2.name));

// Service for subscribing to products
export const streamDashboardService = (stream: Stream, config: AppConfig, logger: AppLoggerService) => {
  const dashboards: Record<number, Dashboard> = {};
  let loading = true;
  let currentId: number | undefined = undefined;
  const cli = new ApiClient(config.apiUrl, '');

  // Public methods

  const modifyDashboard = async (id: number, body: any) => {
    if (body.websocket_uid === undefined) {
      body.websocket_uid = '';
    }

    await stream.modifyDashboard(id, body);
  };

  const updateDashboard = (newDashboard: Dashboard) => {
    dashboards[newDashboard.id] = newDashboard;
  };

  const numDashboards = () => {
    return Object.values(dashboards).length;
  };

  const getDashboard = (id: number | string | undefined): Dashboard | undefined => {
    if (!id) return undefined;
    return dashboards[+id];
  };

  const getCurrentDashboard = () => {
    //logger.info('current dashboard is', currentId);
    return currentId ? dashboards[currentId] : undefined;
  };

  const getCurrentDashboardStrict = () => {
    const dashboard = getCurrentDashboard();
    if (!dashboard) return undefined;
    return dashboard;
  };

  const setCurrentDashboard = (id: number | undefined) => {
    if (id === undefined) {
      logger.info('remove current dashboard');
      currentId = undefined;
    } else if (dashboards[id]) {
      currentId = id;
      logger.info('set current dashboard to', currentId);
    }
  };

  const dashboardList = (): Dashboard[] => {
    return getSortedDashboards(Object.values(dashboards));
  };

  const createDashboard = async (name?: string) => {
    name = name || newName();
    const dashboard = await cli.createDashboard({ name, json: {}, websocket_uid: stream.uid });
    dashboards[dashboard.id] = dashboard;
    return dashboard;
  };

  const deleteDashboard = async (id: number) => {
    await cli.deleteDashboard(id);
    delete dashboards[id];
  };

  const addWidget = async (action: any) => {
    const dashboard = getCurrentDashboardStrict();
    if (!dashboard) return;

    const json = dashboardJson(dashboard);
    const widgets = json.widgets || [];
    const widgetId = uuidv4();
    widgets.push({
      id: widgetId,
      type: action.widgetType,
      payload: action.payload,
    });
    json.widgets = widgets;
    await modifyDashboard(dashboard.id, { ...dashboard, json, name: dashboard.name });
    logger.info('dashboard ', currentId, 'added widget', widgetId);
  };

  const addWidgets = async (dashboard: Dashboard, widgets: any) => {
    const json = dashboardJson(dashboard);
    json.widgets = widgets;
    await modifyDashboard(dashboard.id, { ...dashboard, json, name: dashboard.name });
    logger.info('dashboard ', currentId, 'added some widgets');
  };

  const editWidget = async (widgetId: string, action: any) => {
    try {
      const dashboard = getCurrentDashboardStrict();
      if (!dashboard) return;

      const json = dashboardJson(dashboard);
      json.widgets = (json.widgets || []).map(widget => {
        if (widget.id === widgetId) {
          widget.payload = { ...widget.payload, ...action.payload };
        }
        return widget;
      });
      await modifyDashboard(dashboard.id, { ...dashboard, json, name: dashboard.name, websocket_uid: stream.uid });
    } catch (error: any) {
      toastifyService.showErrorMessage('Something went wrong, please refresh and try again');
      logger.error('Unexpected error', error);
    }
  };

  const deleteWidget = async (widgetId: string) => {
    const dashboard = getCurrentDashboardStrict();
    if (!dashboard) return;

    const json = dashboardJson(dashboard);
    json.widgets = (json.widgets || []).filter(widget => widget.id !== widgetId);
    await modifyDashboard(dashboard.id, { ...dashboard, json, name: dashboard.name });
    logger.info('dashboard ', currentId, 'deleted widget', widgetId);
  };

  const editLayouts = async (layouts: any) => {
    const dashboard = getCurrentDashboardStrict();
    if (!dashboard) return;

    const json = dashboardJson(dashboard);
    json.layouts = { ...json.layouts, ...layouts };
    await modifyDashboard(dashboard.id, { ...dashboard, json, name: dashboard.name, websocket_uid: stream.uid });
    logger.info('dashboard ', currentId, 'layouts updated');
  };

  const newName = (dashboards_?: Dashboard[]) => {
    const keyword = 'dashboard';
    const data = dashboards_ || dashboardList();
    if (data.length === 0) return 'default';
    const index = data.reduce((maxIndex, d) => {
      const bits = d.name.split('-');
      if (bits.length === 2 && bits[0] === keyword) {
        const index = +bits[1];
        if (index > maxIndex) return index;
      }
      return maxIndex;
    }, 0);
    return `${keyword}-${index + 1}`;
  };

  // manage connection
  stream.onConnect(() => {
    stream.subscribe(Subscription.dashboards());
  });

  // handle dashboard events
  stream.onEvent('dashboards', async (event: StreamEvent) => {
    cli.setToken(stream.authToken);
    const data = event.asDashboards();
    if (!data) return;

    data.dashboards.forEach(dashboard => {
      if (data.isDelete) {
        logger.warn('Received delete message for dashboard', dashboard.id, ' - remove it from list');
        if (currentId === dashboard.id) {
          currentId = dashboardList()[0].id;
        }

        delete dashboards[dashboard.id];
      } else {
        logger.info('Received', data.messageType, 'message for dashboard', dashboard.id, ' - add it to the list');
        dashboards[dashboard.id] = dashboard;
      }
    });

    if (numDashboards() === 0 && !dashboardList().find(dash => dash.name !== 'Default')) {
      loading = true;
      logger.warn('No dashboards found - create default one');
      try {
        const dashboard = await cli.createDashboard({ name: 'Default', json: {} });
        dashboards[dashboard.id] = dashboard;
      } catch (error: any) {
        logger.warn(error);
      } finally {
        loading = false;
      }
    } else {
      loading = false;
    }
  });

  return {
    numDashboards,
    getDashboard,
    getCurrentDashboard,
    setCurrentDashboard,
    dashboardList,
    isLoading: () => loading,
    createDashboard,
    modifyDashboard,
    deleteDashboard,
    addWidget,
    addWidgets,
    editWidget,
    deleteWidget,
    editLayouts,
    updateDashboard,
  };
};
