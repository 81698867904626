import { Box, Card, FormControlLabel, Typography, useMediaQuery } from '@mui/material';
import { Snapshot } from '@protos/snapshots';
import { KillSwitch } from '@protos/trading';
import { priceFormatterService } from '@services/PriceFormatterService';
import theme from '@shared/themes/darkTheme';
import { formatDatetime } from '@utils/date';
import { KillSwitchButton } from '../KillSwitchButton';
import { TradingVarCardContent } from './TradingVarCardContent';

const getVarianceBG = (number: string) => {
  const percentage = +number.replace('%', '');

  switch (true) {
    case percentage >= 60 && percentage < 80:
      return '#EFC94C';
    case percentage >= 80 && percentage < 100:
      return '#E27A3F';
    case percentage >= 100:
      return '#DF4949';
    default:
      return 'none';
  }
};

type Props = {
  killSwitch: KillSwitch | undefined;
  onToggleKillSwitch: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  snapshot: Snapshot | undefined;
};

export const TradingRiskKillSwitch: React.FC<Props> = ({ killSwitch, onToggleKillSwitch, snapshot }) => {
  return (
    <>
      <Box display="flex" flexDirection="column" justifyItems="center" alignItems="center" marginBottom={3} marginTop={3}>
        {killSwitch ? (
          <>
            <FormControlLabel
              control={
                <KillSwitchButton
                  sx={{ m: 1, right: -12 }}
                  checked={killSwitch.active}
                  onChange={(event, checked) => onToggleKillSwitch(event, checked)}
                />
              }
              label={`Kill Switch: ${killSwitch.active ? 'On' : 'Off'}`}
            />
            <Typography style={{ fontSize: 11, fontWeight: 800, textAlign: 'center' }}>
              Last triggered by {!killSwitch.user.full_name ? killSwitch.user.id : killSwitch.user.full_name} at{' '}
              {formatDatetime(killSwitch.timestamp)}
            </Typography>
          </>
        ) : (
          'Loading Kill Switch...'
        )}
      </Box>
      <Box display="flex" flex={1} minHeight={useMediaQuery(theme.breakpoints.down('md')) ? 'auto' : 130}>
        <Card
          variant="outlined"
          sx={{
            width: '100%',
            bgcolor: snapshot
              ? getVarianceBG(priceFormatterService.formatVariancePercentage(snapshot.json_data.var, snapshot.json_data.max_var))
              : 'none',
            borderRadius: 0,
            height: useMediaQuery(theme.breakpoints.down('md')) ? 'fit-content' : 'auto',
          }}
        >
          <TradingVarCardContent
            header="VaR %"
            value={snapshot ? priceFormatterService.formatVariancePercentage(snapshot.json_data.var, snapshot.json_data.max_var) : undefined}
          />
        </Card>
        <Card
          variant="outlined"
          sx={{ width: '100%', borderRadius: 0, height: useMediaQuery(theme.breakpoints.down('md')) ? 'fit-content' : 'auto' }}
        >
          <TradingVarCardContent header="Max VaR" value={snapshot ? priceFormatterService.formatVariance(snapshot.json_data.max_var) : undefined} />
        </Card>
        <Card
          variant="outlined"
          sx={{ width: '100%', borderRadius: 0, height: useMediaQuery(theme.breakpoints.down('md')) ? 'fit-content' : 'auto' }}
        >
          <TradingVarCardContent header="VaR" value={snapshot ? priceFormatterService.formatVariance(snapshot.json_data.var) : undefined} />
        </Card>
      </Box>
    </>
  );
};
