import { alpha, styled } from '@mui/material/styles';
import { streamDashboard } from '@services/context';
import { StyledSearchInput } from '@shared/components/StyledComponents';
import { Dashboard } from '@shared/protos/dashboard';
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import ActionMenu, { ActionFooter, ActionHeader } from './DashboardActionsMenu';
import SaveButton, { NAME_MAX_LENGTH } from './EditDashboardSaveButton';

type Props = {
  dashboard: Dashboard;
  onBack: () => void;
};

const StyledStyledSearchInput = styled(StyledSearchInput)(({ theme }) => ({
  padding: '12px 6px',
  paddingLeft: '32px',
  backgroundColor: alpha(theme.palette.text.secondary, 0.1),
}));

const EditDashboard = ({ dashboard, onBack }: Props) => {
  const [inputValue, setInputValue] = useState(dashboard.name);
  const inputRef = useRef<HTMLInputElement>();
  const { modifyDashboard } = streamDashboard;

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value.length > NAME_MAX_LENGTH) {
      return;
    }

    setInputValue(value);
  };

  const handleSave = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await modifyDashboard(dashboard.id, { ...dashboard, name: inputValue, json: dashboard.json });
    onBack();
  };

  useEffect(() => {
    inputRef.current?.select();
  }, []);

  return (
    <form onSubmit={handleSave} autoComplete="off">
      <ActionMenu>
        <ActionHeader onBack={onBack}>
          <StyledStyledSearchInput
            inputRef={inputRef}
            value={inputValue}
            onChange={handleInputChange}
            name="edit-dashboard-name"
            placeholder="Enter name"
          />
        </ActionHeader>
        <ActionFooter>
          <SaveButton inputValue={inputValue} currentDashboardName={dashboard.name} />
        </ActionFooter>
      </ActionMenu>
    </form>
  );
};

export default EditDashboard;
