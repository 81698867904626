import { TradingABNReports } from '@features/admin/trading/components/TradingABNReports';
import { TradingExecutions } from '@features/admin/trading/components/TradingExecutions';
import { TradingOrders } from '@features/admin/trading/components/TradingOrders';
import { TradingExecutionVolume } from '@features/admin/trading/components/execution-volume/TradingExecutionVolume';
import { TradingHedger } from '@features/admin/trading/components/hedger/TradingHedger';
import { TradingOtc } from '@features/admin/trading/components/otc/TradingOtc';
import { TradingRisk } from '@features/admin/trading/components/risk/TradingRisk';
import { Paper, Stack, Tab, Tabs } from '@mui/material';
import { useUserContext } from '@shared/contexts/UserContextProvider';
import { usePageTitle } from '@shared/hooks/usePageTitle';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import NotFoundPage from './NotFoundPage';

type Tab = { label: string; value: string; Component: React.ReactNode };
const TradingAdminTabs: Tab[] = [
  { label: 'Risk', value: 'risk', Component: <TradingRisk /> },
  { label: 'Orders', value: 'orders', Component: <TradingOrders /> },
  { label: 'Executions', value: 'executions', Component: <TradingExecutions /> },
  { label: 'Execution Volume', value: 'execution-volume', Component: <TradingExecutionVolume /> },
  { label: 'ABN Reports', value: 'abn-reports', Component: <TradingABNReports /> },
  { label: 'Hedger', value: 'hedger', Component: <TradingHedger /> },
  { label: 'OTC', value: 'otc', Component: <TradingOtc /> },
];

export const TradingAdminPage = () => {
  usePageTitle('Trading Admin');

  const user = useUserContext();
  const { activeTab: tabInURL } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState<Tab | undefined>();

  const onTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      const tab = TradingAdminTabs.find(tab => tab.value === newValue);
      if (tab && tab.value !== tabInURL) {
        setSelectedTab(tab);
        setSearchParams({});
        navigate(`/admin/trading/${tab.value}`);
      }
    },
    [tabInURL]
  );

  useEffect(() => {
    if (!tabInURL && selectedTab) {
      const params = searchParams.toString();
      navigate(`/admin/trading/${selectedTab.value}${params ? `?${params}` : ''}`);
    } else if (!tabInURL && !selectedTab) {
      setSelectedTab(TradingAdminTabs[0]);
    } else if (tabInURL && !selectedTab) {
      const tab = TradingAdminTabs.find(tab => tab.value === tabInURL);
      if (tab) {
        setSelectedTab(tab);
      }
    } else if (tabInURL && selectedTab && tabInURL !== selectedTab.value) {
      const tab = TradingAdminTabs.find(tab => tab.value === tabInURL);
      if (tab) {
        setSelectedTab(tab);
      }
    }
  }, [tabInURL, selectedTab, searchParams]);

  if (!user?.is_trading_admin) return <NotFoundPage />;

  return selectedTab ? (
    <Stack display="flex" flexDirection="column" flex={1} gap={1} padding={2}>
      <Stack display="flex" flexDirection="row" gap={1}>
        <Paper style={{ flex: 9 }}>
          <Tabs value={selectedTab.value} onChange={onTabChange} variant="scrollable" sx={{ maxWidth: 'calc(100vw - 106px)' }}>
            {TradingAdminTabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} style={{ fontSize: 11 }} />
            ))}
          </Tabs>
        </Paper>
      </Stack>
      <Paper sx={{ flex: 1, minHeight: '600px' }}>{selectedTab.Component}</Paper>
    </Stack>
  ) : null;
};
